import styled from 'styled-components';
import { media } from '../utils/media';

export const SectionSubtitle: any = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  position: relative;
  margin: -0.7rem 0 1rem 0;

  @media ${media.tablet} {
    font-size: 1.7rem;
  }

  @media ${media.phone} {
    font-size: 1.4rem;
  }

`;
